<template>
  <div class="container Nunito" style="padding-top: 25px">
    <h1 class="title is-3 has-text-white has-text-centered" style="margin-bottom: 2rem;">{{ this.$store.getters.morning ? $t("dashboard.selector.morning") : $t("dashboard.selector.afternoon") }}<b-tooltip :label="`Sorting By: ${this.sort}`" position='is-bottom'><b-icon :class="{'is-disabled': !this.loaded}" icon="sort-amount-down" pack="fas" @click.native="changeSort()" style="font-size: 15px; width: 1.5rem; cursor: pointer;"></b-icon></b-tooltip></h1>
    <div v-if="!loaded">
      <div class="container" style="width:50%">
        <div class="columns is-mobile is-centered is-multiline">
          <div class="column is-flex has-text-centered is-full customcolumn has-shadow">
            <div class="b-skeleton is-animated" style="width: unset !important;">
              <div class="b-skeleton-item is-rounded has-shadow" style="height: 80px; width: 80px; border-radius: 50%;">
              </div>
            </div>
            <div class="b-skeleton is-animated" style="width: unset !important;">
              <div class="b-skeleton-item is-rounded has-shadow" id="g5489adaw" style="height: 1rem;width: 206px;margin: 0px 1em;margin-top: 9%;">
              </div>
            </div>
          </div>
          <div class="column is-flex has-text-centered is-full customcolumn has-shadow">
            <div class="b-skeleton is-animated" style="width: unset !important;">
              <div class="b-skeleton-item is-rounded has-shadow" style="height: 80px; width: 80px; border-radius: 50%;">
              </div>
            </div>
            <div class="b-skeleton is-animated" style="width: unset !important;">
              <div class="b-skeleton-item is-rounded has-shadow" id="g5489adaw" style="height: 1rem;width: 206px;margin: 0px 1em;margin-top: 9%;">
              </div>
            </div>
          </div>
          <div class="column is-flex has-text-centered is-full customcolumn has-shadow">
            <div class="b-skeleton is-animated" style="width: unset !important;">
              <div class="b-skeleton-item is-rounded has-shadow" style="height: 80px; width: 80px; border-radius: 50%;">
              </div>
            </div>
            <div class="b-skeleton is-animated" style="width: unset !important;">
              <div class="b-skeleton-item is-rounded has-shadow" id="g5489adaw" style="height: 1rem;width: 206px;margin: 0px 1em;margin-top: 9%;">
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-mobile is-centered is-multiline">
          <div class="column is-flex has-text-centered is-full customcolumn has-shadow">
            <div class="b-skeleton is-animated" style="width: unset !important;">
              <div class="b-skeleton-item is-rounded has-shadow" style="height: 80px; width: 80px; border-radius: 50%;">
              </div>
            </div>
            <div class="b-skeleton is-animated" style="width: unset !important;">
              <div class="b-skeleton-item is-rounded has-shadow" id="g5489adaw" style="height: 1rem;width: 206px;margin: 0px 1em;margin-top: 9%;">
              </div>
            </div>
          </div>
          <div class="column is-flex has-text-centered is-full customcolumn has-shadow">
            <div class="b-skeleton is-animated" style="width: unset !important;">
              <div class="b-skeleton-item is-rounded has-shadow" style="height: 80px; width: 80px; border-radius: 50%;">
              </div>
            </div>
            <div class="b-skeleton is-animated" style="width: unset !important;">
              <div class="b-skeleton-item is-rounded has-shadow" id="g5489adaw" style="height: 1rem;width: 206px;margin: 0px 1em;margin-top: 9%;">
              </div>
            </div>
          </div>
          <div class="column is-flex has-text-centered is-full customcolumn has-shadow">
            <div class="b-skeleton is-animated" style="width: unset !important;">
              <div class="b-skeleton-item is-rounded has-shadow" style="height: 80px; width: 80px; border-radius: 50%;">
              </div>
            </div>
            <div class="b-skeleton is-animated" style="width: unset !important;">
              <div class="b-skeleton-item is-rounded has-shadow" id="g5489adaw" style="height: 1rem;width: 206px;margin: 0px 1em;margin-top: 9%;">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="container" style="width:50%">
        <div class="columns is-mobile is-centered is-multiline">
          <router-link :class="{'is-premium-1' : (guild.guildDB.data ? (guild.guildDB.data.premium === 1 ? true : false) : false ), 'is-disabled': (guild.guildDB.data ? (guild.guildDB.data.banned ? true : ( guild.from_back && $route.query.banned ? true : false)) : false) }" class="column is-flex has-text-centered is-full customcolumn has-shadow" v-for="guild in guilds" :id="guild.id" :key="guild.id" :to="{ path: '/dashboard/' + guild.id }" tag="div">
            <div v-if="guild.icon" id="adwad5t" style="min-width: fit-content !important;min-height: fit-content !important;">
              <img class="icon-img has-shadow" v-if="!guild.icon.includes('a_')" :class="{'static': guild.icon.includes('a_')}" :src="'https://cdn.discordapp.com/icons/' + guild.id + '/' + guild.icon + '.webp?size=80'" :alt="guild.name" style="height: 80px !important; width: 80px !important;">
              <img class="icon-img has-shadow" v-else :src="'https://cdn.discordapp.com/icons/' + guild.id + '/' + guild.icon + '.gif?size=80'" :alt="guild.name" style="height: 80px !important; width: 80px !important;">
            </div>
            <div class="icon-img-empty has-shadow Nunito" id="adwad5t" v-else>
              {{ guild.name.replace(/'s /g, ' ').replace(/\w+/g, e => e[0]).replace(/\s/g, '') }}
            </div>
            <h6 class="title is-5 has-text-white Nunito" style="line-height: 80px;margin: 0px 1em;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">{{ guild.name }}</h6>
          </router-link>
        </div>
      </div>
    </div>
      <div class="container" style="width:50%">
        <div class="columns is-mobile is-centered is-multiline">
          <b-tooltip :v-if="(this.$store.getters.user ? (this.$store.getters.user.premium <= 0) : true)" type="is-info">
            <template v-slot:content><b>Why do we show ads?</b>. <br>The simple answer is to make some money to keep us going.<br>We made sure to place them in a way so your expirence isnt damaged.<br>If you dont want to see ads support us by getting premium!</template>
            <Adsense v-if="(this.$store.getters.user ? (this.$store.getters.user.premium <= 0) : true) && !this.$window767.matches && this.$store.getters.google.showAds" style="text-align: center;" ins-style="display:block;margin-top: 25px;height:90px;width:728px;text-align: center;" :data-ad-client="this.$store.getters.google.adClient" :data-ad-slot="this.$store.getters.google.dashboardSelector"></Adsense>
            <Adsense v-else-if="(this.$store.getters.user ? (this.$store.getters.user.premium <= 0) : true) && this.$window767.matches && this.$store.getters.google.showAds" style="text-align: center;" ins-style="display:block;margin-top: 25px;height:250px;width:250px;text-align: center;" :data-ad-client="this.$store.getters.google.adClient" :data-ad-slot="this.$store.getters.google.dashboardSelector"></Adsense>
          </b-tooltip>
        </div>
      </div>
  </div>
</template>
<script>
// @ is an alias to /src
  export default {
    name: 'dashboard',
    components: {},
    data () {
      return {
        guilds: [],
        loaded: false,
        sort: localStorage.getItem('dashboard-select-sort') || 'alphabetically',
        runGuild: false
      }
    },
    watch: {
    },
    created () {
      if (!localStorage.getItem('dashboard-select-sort')) localStorage.setItem('dashboard-select-sort', 'alphabetically')
      this.$store.dispatch('getGuilds', {
        // chunk: null,
        filter: 'isManager',
        sort: this.sort
      }).then(async (data) => {
        this.guilds = this.runGuildSort(data)
        this.loaded = true
      })
    },
    methods: {
      changeSort () {
        // Sort Options = alphabetically, premium, invite
        if (this.sort === 'alphabetically') {
          localStorage.setItem('dashboard-select-sort', 'premium')
          this.sort = 'premium'
        } else if (this.sort === 'premium') {
          localStorage.setItem('dashboard-select-sort', 'invite')
          this.sort = 'invite'
        } else if (this.sort === 'invite') {
          localStorage.setItem('dashboard-select-sort', 'alphabetically')
          this.sort = 'alphabetically'
        }
        this.loaded = false
        this.$store.dispatch('getGuilds', {
          // chunk: 3,
          filter: 'isManager',
          sort: this.sort
        }).then(async (data) => {
          this.guilds = this.runGuildSort(data)
          this.loaded = true
        })
      },
      runGuildSort (guilds) {
        if (!this.$route.query.from_guild_id || !guilds) return guilds
        if (!guilds.find(g => g.id === this.$route.query.from_guild_id)) return guilds
        return [{ ...guilds.find(g => g.id === this.$route.query.from_guild_id), from_back: true }, ...guilds.filter(g => g.id !== this.$route.query.from_guild_id)]
      }
    }
  }
</script>

<style scoped>
    @media only screen and (max-width: 768px) {
        /* #adwad5t {
            display: none !important;
        } */
        #g5489adaw {
          height: 1rem !important;
          width: 64px !important;
          margin: 25% 1em 0px !important;
        }
        /* .columns {
          padding-top: 0.75rem !important;
        } */
    }
  .static:hover {
    opacity:0;
  }
  .has-shadow {
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important
  }
  .customcolumn {
    border-radius: 1px;
    border-color: transparent;
    border-width: 1px;
    border-radius: 10px;
    border-style: solid;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    margin-bottom: 10px;
    cursor: pointer;
    transition: .3s;
    background-color: var(--main-color-bg-1) !important;
  }
  .customcolumn:hover {
    filter: brightness(.6);
    -webkit-filter: brightness(.6);
    transform: scale(1.05);
    transition: all .3s ease-in-out;
  }
  .icon-img {
    float: left;
    border-radius:50px;
    border-color: var(--main-color-bg-3);
    border-width: 2px;
    border-radius: 50px;
    border-style: solid;
    background-color: var(--main-color-bg) !important;
  }
  .icon-img-empty {
    min-height:80px;
    min-width:80px;
    border-color: var(--main-color-bg-3);
    border-width: 2px;
    border-radius: 50px;
    border-style: solid;
    line-height: 75px;
    background-color: var(--main-color-bg) !important;
  }
  .welcomeHR {
    width: 50%;
    height: 0.5px;
  }
  .card {
    background-color: var(--main-color-bg) !important;
    height: 200px;
    border-color: #6c757d!important;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    cursor: pointer;
    transition: .3s;
  }
  .card:hover {
    transform: scale(1.05);
    transition: all .3s ease-in-out;
    filter: brightness(.5);
    -webkit-filter: brightness(.5);
    overflow: hidden;
  }
  .card .dashboard-image {
    height: 198px;
  }

  .card .dashboard-image img {
    height: inherit;
    object-fit: cover;
    width: 100% !important;
    border-radius: 0.25rem;
  }
  .card .dashboard-image h4 {
    text-align: center;
    padding-top: 20%;
    font-weight: normal!important;
  }
</style>
